// Exact same content as app/assets/javascripts/cookies.js but wrapped as an helper with es6 export keywords
// To be used in bundler content since sprockets managed js don't play well with bundler's
export const getCookie = (name) => {
  let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

export const setCookie = (name, value, days) => {
  let d = new Date;
  d.setTime(d.getTime() + 24*60*60*1000*days);
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

export const deleteCookie = (name) => setCookie(name, '', -1);
